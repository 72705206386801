import { Link } from "gatsby";
import React from "react";

const Footer = props => (
	<footer className="footer">
        <div className="container">
        	<div className="copy">
          		<span className="copy-dimedix">© {new Date().getFullYear()} dimedix</span>
          		<span className="copy-cocowat">Creado por <a href="https://www.cocowat.com" title="Cocowat | Diseño y desarrollo web a medida" rel="noopener noreferrer" target="_blank">cocowat</a></span>
          	</div>
		
			<ul className="list-legal">
				<li><Link to="/aviso-legal" title="Aviso legal">Aviso legal</Link></li>
				<li><Link to="/politica-privacidad" title="Política de privacidad">Política de privacidad</Link></li>
				<li><Link to="/politica-cookies" title="Política de cookies">Política de cookies</Link></li>
			</ul>
        </div>
	</footer>
)

export default Footer
